import ReactDOM from "react-dom";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Modal, Tabs} from "antd";
import "antd/dist/antd.css";
import {Checkbox, FormControlLabel, TextField} from "@material-ui/core";
import "../reusable-components/styles/CustomModal.css";
import DoximityBackgroundImg from "../../../images/MyCE/ui2/DoximitySignIn.png";
import OktaLogo from "../../../images/MyCE/ui2/okta-logo.png";
import axios from "axios";

let url = window.location;
const BASE_URL = url.protocol + "//" + url.host + "/" + url.pathname.split('/')[1];

const isProd = () => {
  return window.location.href.indexOf("app.myceapp.com") > -1
};

const ForgotPasswordUrl = url.href.indexOf("app.myceapp.com") > -1 ? '/SignIn/ForgetPassword' : url.href.indexOf('3.93.138.158') > -1 ? 'http://3.93.138.158:8080/addCE-web2/SignIn/ForgetPassword' : 'http://localhost:8080/addCE_web_war/SignIn/ForgetPassword'

const getBackendServerUrl = () => {
  // checking for test server and then determining the serverUrl
  let serverUrl = "https://ce-backend.app.aceaglobal.com/user/login";
  if (window.location.href.indexOf("3.93.138.158") > -1) {
    serverUrl = "https://ce-backend.staging.app.aceaglobal.com/user/login";
  } else if (window.location.href.indexOf("localhost:8080/addCE_web_war") > -1) {
    serverUrl = "http://localhost:8090/user/login"
  }
  return serverUrl;
}

const {TabPane} = Tabs;

const useStyles = makeStyles({
  textFieldBase: {
    fontSize: "2rem",
    backgroundColor: "#F5F5F5",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,.04)",
    },
    "&$focused": {
      backgroundColor: "#f3f4f6",
    },
  },
  textFieldInputMultiline: {
    padding: "10px 0px 0px 0px"
  },
  textFieldLabel: {
    fontSize: "1.5rem",
    fontFamily: "inherit",
    transform: "translate(12px, 22px) scale(1)",
    "&$focused": {
      color: "rgba(0,0,0,.6)",
    },
  },
  signInButton: {
    minHeight: "3.6rem",
    border: "none",
    borderRadius: "5px",
    color: "#fff",
    backgroundColor: "#9AC2FF",
    "&:hover": {
      backgroundColor: "#5894C3",
    },
    width: "100%",
    height: "48px",
    fontSize: "16px",

    '&:disabled': {
      backgroundColor: '#e0e0e0',
      color: '#a6a6a6'
    }
  },
  rememberMe: {
    marginLeft: "-5px",
    marginTop: "2px",
  },
  rememberMeCheck: {
    transform: "scale(2)",
  },
  rememberMeLabel: {
    fontSize: "16px",
  },
  forgetPassword: {
    marginTop: "2px",
    float: "right",
    fontSize: "16px",
  },
  centered: {
    textAlign: "center",
  },
  doximitySignIn: {
    backgroundImage: `url(${DoximityBackgroundImg})`,
    backgroundSize: "cover",
    overflow: "hidden",
    width: "23rem",
    height: "calc(23rem * .1834)",
    marginLeft: "calc((100% - 23rem) / 2)",
    marginTop: "2rem",
    marginBottom: "2rem"
  },
  oktaLogin: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#00297A',
    width: '23rem',
    margin: 'auto',
    height: 'calc(23rem * 0.1834)',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px'
  },
  oktaText: {
    textAlign: 'center',
    width: '100%',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '16px',
    margin: '0',
    height: 'fit-content'
  },
  helperText: {
    fontSize: "1.5rem",
  }
});

/**
 * This is assigned to a function that changes the state of the modal so that it
 * can be opened again if the user closes it
 */
let reopenModalSignIn;
let reopenModalSignUp;

/**
 * Function contains the definition for the sign in/sign up modal.
 *
 * The modal uses the normal ant design modal rather than the CustomModal reusable
 * component as it is more different than most of the modals throughout the site
 */
function SignInCreateAccountModal(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [conditionalRender, setConditionalRender] = useState({
    showTabHeaders: true,
    loginSrc: true,
    showDoximityAtTop: true
  });
  const [openTab, setOpenTab] = useState(props.defaultTab); // 0 for sign in, 1 for create account
  const [values, setValues] = useState({
    j_username: props.email ? props.email : "",
    j_password: "",
    confirmPassword: "",
    rememberMe: true
  });
  const [errors, setErrors] = useState({
    wrongEmailPassword: props.error && props.error != "3" ? true : false,
    inactiveAccountError: props.error == "3" ? true : false,
    partnerAccountCreationError: props.partnerOrgName && props.partnerOrgUrl,
    username: false,
    password: false,
    confirmPassword: false,
    passwordMatch: false,
    emailExists: false,
  });
  const [isLoading, setLoading] = useState(false);

  /**
   * Styles
   */
  const TextFieldInputLabelProps = {
    classes: {
      root: classes.textFieldLabel,
      focused: classes.focused,
    },
  };

  const TextFieldInputProps = {
    classes: {
      root: classes.textFieldBase,
      focused: classes.focused,
      inputMultiline: classes.textFieldInputMultiline,
    },
  };

  // arguments for login and sign up from /certificate/welcome/x, for both handlers
  reopenModalSignIn = function (showTabHeader, loginSrc, emailAddress) {
    setOpen(true);
    setOpenTab(0);
    if (showTabHeader) {
      setConditionalRender({...conditionalRender, showTabHeaders: false, loginSrc: loginSrc});
    }
    if (emailAddress) {
      setValues({...values, j_username: emailAddress})
    }
  }

  reopenModalSignUp = function (emailAddress) {
    setOpen(true);
    setOpenTab(1);
    if (emailAddress) {
      setValues({...values, j_username: emailAddress})
      setConditionalRender({...conditionalRender, showDoximityAtTop: false});
    }
  }

  const handleClose = () => {
    setOpen(false);
  }

  const switchTab = (activeKey) => {
    if (activeKey == "signIn") {
      setOpenTab(0);
    } else if (activeKey == "createAccount") {
      setOpenTab(1);
    }
  }

  const handleSignInSubmit = async (event) => {

    // check for valid username
    if (values.j_username.length == 0) {
      setErrors({...errors, ["username"]: true});
      event.preventDefault();
      return false;
    }

    // check for valid password
    if (values.j_password.length == 0) {
      setErrors({...errors, ["password"]: true});
      event.preventDefault();
      return false;
    }

    // store email
    setLoading(true);
    if (values.rememberMe) {
      window.localStorage.setItem('email', values.j_username);
    } else {
      window.localStorage.removeItem('email');
    }
    const serverUrl = getBackendServerUrl();
    try {
      const response = await axios.post(serverUrl, {
        username: values.j_username,
        password: values.j_password
      });
      localStorage.setItem("tokensData", JSON.stringify(response.data.payload));
    } catch (e) {
      console.log('e', e)
    } finally {
      setLoading(false);
      let elementById = document.getElementById("react-sign-in-form");
      elementById.submit();
    }
    return true;
  }

  const handleSignUpSubmit = async (event) => {
    if (isLoading) return false;
    // check for valid username
    if (values.j_username.length == 0) {
      setErrors({...errors, ["username"]: true});
      event.preventDefault();
      return false;
    }

    // check for valid password
    if (values.j_password.length == 0) {
      setErrors({...errors, ["password"]: true});
      event.preventDefault();
      return false;
    }

    // check for valid password confirm
    if (values.confirmPassword.length == 0) {
      setErrors({...errors, ["confirmPassword"]: true});
      event.preventDefault();
      return false;
    }

    // check for passwords match
    if (values.j_password !== values.confirmPassword) {
      setErrors({...errors, ["passwordMatch"]: true});
      event.preventDefault();
      return false;
    }

    event.preventDefault();

    setLoading(true);
    let isreferal = 1;
    let referalid = $.cookie("referaluserid");

    if (referalid === undefined) {
      referalid = 'xk0Nz/VCyJk=';
    } else if (referalid == 'xk0Nz/VCyJk=') {
      isreferal = 0;
    }

    let signUpAJAXurl = "./SignUp/CreateUser";
    let signUpRedirecturl = "./signUpSuccess?signUp=email";
    // denoting that it came from the /certificate/welcome/x
    if (!conditionalRender.showDoximityAtTop) {
      signUpAJAXurl = "../../SignUp/CreateUser";
      signUpRedirecturl = "../../signUpSuccess?signUp=email";
    }

    const dataToPost = {
      EmailId: values.j_username,
      Password: values.j_password,
      encrypt_referalId: referalid,
      is_referal: isreferal
    };

    if (window.location.href.indexOf("certificate/welcome") > -1) {
      const href = window.location.href;
      let certCode = href.substring(href.indexOf("welcome/") + 8);
      certCode = certCode.replaceAll("/", "");
      dataToPost["cert_code"] = certCode;
      signUpRedirecturl = `../../dashboard/cert/${certCode}`;
    }

    $.ajax({
      type: "POST",
      url: signUpAJAXurl,
      data: dataToPost,
      dataType: "text",
      success: function (data) {
        if (data == "callback(0)") {
          setErrors({...errors, ["emailExists"]: true});
        } else {
          (async () => {
            try {
              let serverUrl = getBackendServerUrl();
              const response = await axios.post(serverUrl, {
                username: values.j_username,
                password: values.j_password
              });
              localStorage.setItem("tokensData", JSON.stringify(response.data.payload));
            } catch (e) {
              console.log('e', e)
            } finally {
              window.location = signUpRedirecturl;
            }
          })();
        }
      },
      error: function (request, status, error) {
        alert("Error during SignUp: " + error);
      },
      completed: function() {
        setLoading(false);
      }
    });
  }

  const handleChange = (e) => {
    // reset errors
    setErrors({...errors, ["username"]: false});
    setErrors({...errors, ["password"]: false});
    setErrors({...errors, ["confirmPassword"]: false});
    setErrors({...errors, ["passwordMatch"]: false});
    setErrors({...errors, ["password"]: false});
    setErrors({...errors, ["emailExists"]: false});

    const {name, value} = e.target;
    setValues({...values, [name]: value});
  };

  const handleRememberMeChange = (e) => {
    setValues({...values, ["rememberMe"]: e.target.checked});
  };

  return (
    <Modal
      visible={open}
      onCancel={handleClose}
      title={conditionalRender.showTabHeaders ? (<Tabs className="sign-in-create-account-modal-tabs"
                                                       activeKey={openTab == 0 ? "signIn" : "createAccount"}
                                                       size="large"
                                                       centered
                                                       onChange={switchTab}
      >
        <TabPane id="signInTab" key="signIn" tab="Sign In"></TabPane>
        <TabPane id="createAccountTab" key="createAccount" tab="Create Account"></TabPane>
      </Tabs>) : null
      }
      footer={null}
    >
      {openTab === 0 ? (
        <div>
          <h1>Sign in</h1>
          <h3 style={{display: (errors.wrongEmailPassword && !errors.partnerAccountCreationError) ? "block" : "none"}}>
            There was an error with your Email/Password combination. Please try again.
          </h3>
          <h3 style={{display: errors.partnerAccountCreationError ? "block" : "none"}}>
            Please Sign in using your ALTA account
          </h3>
          <h3 style={{display: errors.inactiveAccountError ? "block" : "none"}}>
            Your account needs to be activated before you can enjoy the features of the CE App!
          </h3>

          <form
            method="post"
            id="react-sign-in-form"
            onSubmit={async (event) => {
              if (isLoading) return false;
              event.preventDefault();
              const data = await handleSignInSubmit(event);
            }}
            action={BASE_URL + "/j_spring_security_check"}
          >
            <div className="form-group">
              <TextField
                id="email-input-f"
                variant="filled"
                type="email"
                label="Email address"
                name="j_username"
                value={values.j_username}
                onChange={handleChange}
                InputLabelProps={TextFieldInputLabelProps}
                InputProps={TextFieldInputProps}
                fullWidth
                error={errors.username}
                helperText={errors.username ?
                  <span className={classes.helperText}>Email address is required</span> : ""}
              >
              </TextField>
            </div>
            <div className="form-group">
              <TextField
                variant={"filled"}
                type="password"
                label="Password"
                name="j_password"
                value={values.password}
                onChange={handleChange}
                InputLabelProps={TextFieldInputLabelProps}
                InputProps={TextFieldInputProps}
                fullWidth
                error={errors.password}
                helperText={errors.password ? <span className={classes.helperText}>Password is required</span> : ""}
              >
              </TextField>
            </div>
            <div className="form-group">
              <input
                disabled={isLoading}
                className={classes.signInButton}
                type="submit"
                value="Sign in with Email"
                id="sign-in-email-button"
              />
            </div>
            <div className="form-group">
                                <span>
                                    <FormControlLabel
                                      className={classes.rememberMe}
                                      control={
                                        <Checkbox
                                          name="_spring_security_remember_me"
                                          name="persist-login"
                                          className={classes.rememberMeCheck}
                                          checked={values.rememberMe}
                                          onChange={handleRememberMeChange}
                                          color="primary"
                                        />
                                      }
                                      label={<span className={classes.rememberMeLabel}>Remember me</span>}
                                    />
                                    <a className={classes.forgetPassword} href={ForgotPasswordUrl}>Forget password?</a>
                                </span>
            </div>
          </form>
          {(conditionalRender.loginSrc === "doximity" || conditionalRender.showTabHeaders) ? (
            <>
              <h3 className={classes.centered}>or</h3>
              <a href="./socialauth?id=doximity">
                <div className={classes.doximitySignIn}></div>
              </a>
              <a href={isProd() ? 'https://accounts.myceapp.com/acea/login?idp=okta-babylon' : 'https://accounts-test.myceapp.com/acea/login?idp=okta'}>
                <div className={classes.oktaLogin}>
                  <img style={{width: '30%'}} src={OktaLogo} alt="okta-idp"/>
                  <h4  className={classes.oktaText}>Sign in with OKTA</h4>
                </div>
              </a>
            </>
          ) : null}
          {conditionalRender.showTabHeaders ? (
            <p className={classes.centered}>Not a member? <a onClick={() => switchTab("createAccount")}>Sign up</a></p>
          ) : null}
        </div>
      ) : (
        <div>
          {((conditionalRender.loginSrc === "doximity" || conditionalRender.showTabHeaders) && (conditionalRender.showDoximityAtTop)) ? (
            <>
              <h1 className={classes.centered}>Join thousands of your colleagues</h1>
              <p className={classes.centered}>Make continuing education an enjoyable experience</p>
              <a href="./socialauth?id=doximity">
                <div className={classes.doximitySignIn}></div>
              </a>
              <h3 className={classes.centered}>or</h3>
            </>
          ) : null}
          <form method="post"
                id="sign-in-pop-up-form"
                onSubmit={handleSignUpSubmit}
          >
            <div className="form-group">
              <TextField
                variant="filled"
                type="email"
                label="Email address"
                name="j_username"
                value={values.j_username}
                onChange={handleChange}
                InputLabelProps={TextFieldInputLabelProps}
                InputProps={TextFieldInputProps}
                fullWidth
                error={errors.username || errors.emailExists}
                helperText={errors.username ? <span className={classes.helperText}>Email address is required</span> :
                  errors.emailExists ?
                    <span className={classes.helperText}>This account already exists. Please check your email for a link.</span> :
                    ""}
              >
              </TextField>
            </div>
            <div className="form-group">
              <TextField
                variant={"filled"}
                type="password"
                label="Password"
                name="j_password"
                value={values.password}
                onChange={handleChange}
                InputLabelProps={TextFieldInputLabelProps}
                InputProps={TextFieldInputProps}
                fullWidth
                error={errors.password || errors.passwordMatch}
                helperText={errors.password ? <span className={classes.helperText}>Password is required</span> :
                  errors.passwordMatch ? <span className={classes.helperText}>Passwords do not match</span> :
                    ""}
              >
              </TextField>
            </div>
            <div className="form-group">
              <TextField
                variant={"filled"}
                type="password"
                label="Confirm password"
                name="confirmPassword"
                value={values.password}
                onChange={handleChange}
                InputLabelProps={TextFieldInputLabelProps}
                InputProps={TextFieldInputProps}
                fullWidth
                error={errors.confirmPassword || errors.passwordMatch}
                helperText={errors.confirmPassword ? <span className={classes.helperText}>Password is required</span> :
                  errors.passwordMatch ? <span className={classes.helperText}>Passwords do not match</span> :
                    ""}
              >
              </TextField>
            </div>
            <div className="form-group">
              <input
                disabled={isLoading}
                className={classes.signInButton}
                type="submit"
                value="Sign Up - Free"
                id="sign-up-email-button"
              />
            </div>
            {!conditionalRender.showDoximityAtTop ? (
              <>
                <h3 className={classes.centered}>or</h3>
                <a href="./socialauth?id=doximity">
                  <div className={classes.doximitySignIn}></div>
                </a>
                <a href={isProd() ? 'https://accounts.myceapp.com/acea/login?idp=okta-babylon' : 'https://accounts-test.myceapp.com/acea/login?idp=okta'}>
                  <div className={classes.oktaLogin}>
                    <img style={{width: '30%'}} src={OktaLogo} alt="okta-idp"/>
                    <h4  className={classes.oktaText}>Sign in with OKTA</h4>
                  </div>
                </a>
              </>
            ) : null}
            <p className={classes.centered}>Are you an organization? <a href="https://aceaglobal.com/lp/ceapp-groups/"
                                                                        target="_blank">Start Here</a></p>
            <p className={classes.centered}><a href="https://myceapp.com/terms">Terms</a></p>
          </form>
        </div>
      )}
    </Modal>
  )
}

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return (false);
}

window.onload = (event) => {
  let signIn = getQueryVariable("signIn");
  let signUp = getQueryVariable("signUp");
  let email = getQueryVariable("email");
  let error = getQueryVariable("error");
  let sso = getQueryVariable("sso");
  let partnerOrgUrl = getQueryVariable("partnerOrgUrl");
  let partnerOrgName = getQueryVariable("partnerOrgName");

  const partnerOrg = document?.cookie
    ?.split('; ')
    ?.find(row => row.startsWith('partnerOrg='))
    ?.split('=')[1];
  const hrefPath = `./?signIn=true&sso=${partnerOrg ? partnerOrg : sso}`;

  const openPartnerModal = () => {
    if (window.location.href === hrefPath) {
      return;
    }
    window.location.href = hrefPath;
  };

  // alta users have their own sign in/create account modal
  let props = {
    defaultTab: 0,
    email: email,
    error: error,
    partnerOrgUrl: partnerOrgUrl,
    partnerOrgName: partnerOrgName
  };

  ReactDOM.render(<SignInCreateAccountModal {...props}/>, document.getElementById("sign-in-create-account-modal"));

  if (partnerOrgUrl && partnerOrgName) {
    $.notify({
      title: `Member of ${partnerOrgName}<br>`,
      message: `<strong>Please sign in via your ${partnerOrgName} account by clicking the link below</strong><br><a href="./?signIn=true&sso=${partnerOrg}">Click Here</a>`
    }, {
      type: 'warning',
      allow_dismiss: true,
      delay: 5000,
      mouse_over: "pause",
      placement: {
        from: "top",
        align: "right"
      },
      offset: {
        x: 20,
        y: 50
      }
    });
  }

  if (error !== false && error !== "") {
    reopenModalSignIn();
  } else if (signIn === "true" && sso === false) {
    reopenModalSignIn();
  }

  const saveCertificateButton = document.getElementById("save-certificate-button");
  const userExistenceStatus = document.getElementById("userExistenceStatus");
  const loginSrc = document.getElementById("loginSrc");
  const emailAddress = document.getElementById("emailAddress");
  if (saveCertificateButton && userExistenceStatus) {
    saveCertificateButton.onclick = () => {
      const val = userExistenceStatus.value;
      if (val === "does_not_exists") {
        reopenModalSignUp(emailAddress.value || "");
      } else if (val === "exists_confirmed") {
        reopenModalSignIn(true, loginSrc.value, emailAddress.value);
      } else {
        // logic for account activation, in certificate_distribution_page.js
      }
    }
    return;
  }

  const signInButton = document.getElementById("sign-in-button");
  if (signInButton) {
    signInButton.onclick = () => {
      if (partnerOrg || sso !== false) {
        openPartnerModal();
      } else {
        reopenModalSignIn();
      }
    };
  }

  const signUpButton = document.getElementById("sign-up-button");
  if (signUpButton) {
    signUpButton.onclick = () => {
      if (partnerOrg || sso !== false) {
        openPartnerModal();
      } else {
        reopenModalSignUp();
      }
    };
  }

  document.getElementById("open-sign-in-r-modal").onclick = () => {
    $('html,body').animate({scrollTop: 0}, 1000);
    if (partnerOrg || sso !== false) {
      openPartnerModal();
    } else {
      reopenModalSignIn();
    }
  };
}
